.resetSetButton {
  background: #ED4337;
  border: none;
  padding: 10px 30px;
  border-radius: 3px;
  cursor: pointer;
}

.includeSetBox {
  padding-left: 20px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.square {
  float: left;
  position: relative;
  width: 23%;
  padding-bottom: 20%;
  margin: 1%;
  background-color: #333;
  overflow: hidden;
}

.home-gmk {
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 0;
}

.content {
  position: absolute;
  padding: 5%;
  width: auto;
  height: auto;
}

.rs {
  max-height: 90%;
  max-width: 100%;
}

.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-button {
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  font-size: 32px;
}

.home-button:hover {
  text-decoration: none;
  color: #f2f2f2;
}

.graph {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-header {
  font-size: 40px;
}

.home-page {
  display: flex;
  flex-direction: column;
}

.info {
  background-color: #333;
  color: #fff;
  padding: 20px;
}

dt {
  font-size: 26px;
}

dd {
  font-size: 20px;
}